html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.nav-item .nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #013289;
  white-space: nowrap;
  transition: 0.3s;
} 
.nav-item a:hover, 
.nav-item .active, 
.nav-item .active:focus, 
.nav-item li:hover>a {
  color: #4154f1;
}
.logo {
  width: 250px;
}
.carousel-container,
.what-we-do-items.row,
.engineering-services-items.row {
  margin-top: 50px;
  margin-bottom: 50px;
}
.electrical-designing-img {
  max-height: 600px;
  width: 100%;
  border-radius: 50px;
}
.header-titles {
  font-size: 38px;
  font-weight: 700;
  color: #012970;
}
.image-shadow {
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
  border: 2px solid white;
  filter: blur(5px);
  transition: transform 0.5s ease 0s;
  border-radius: 50px;
}
.text-on-image {
  color: #fff;
}
.text-on-image.text-paragraph {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}
.footer-widget-wrapper {
  background-color: #222222;
  color: #aaaaaa;
  padding: 40px 0;
}
.footer-paragraph-title {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
}
.footer-bottom {
  background-color: #333333;
  padding: 10px 0;
  color: white;
}
.footer-bottom p {
  margin: 0;
}
.google-maps-container {
  padding: 10px 0;
}
.call-us-phone {
  color: #aaaaaa;
  text-decoration: none;

}
.call-us-phone:hover {
  color: #fff;
  text-decoration: none;
}
.find-us {
  color: #aaaaaa;
}
.find-us:hover {
  color: #fff;
}
.sections p {
  font-size: 20px;
}
.sections ul li {
  font-size: 20px;
}
.what-we-do-items .card,
.engineering-services-items .card {
  border: none;
  border-radius: 0;
  background-color: #f8f9fa;
  min-height: 192px;
}
.what-we-do-items svg,
.engineering-services-items svg,
.contact-us-items svg {
  color: #013289;
  transform: scale(1.0);
}
.what-we-do-items svg:hover,
.engineering-services-items svg:hover,
.contact-us-items svg:hover {
  color: #013289;
  transform: scale(1.2);
}
h5.card-title {
  margin-top: 5px;
}
.working-hours {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}
.working-hours p {
  margin:0;
}
.working-hours-container {
  max-width: 280px;
  margin: 0 auto;
}
.contact-us-section-container {
  margin-top: 50px;
  margin-bottom: 50px;
}
.contact-us-section-container .card .card-text {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  color: #444444;
}
.contact-us-items {
  padding: 15px 0;
}
.contact-us-items .card {
  border: none;
  border-radius: 0;
  background-color: #f8f9fa;
  min-height: 192px;
}
#contact-us {
  margin-bottom: 20px;
}
.contact-form .submit-btn-contact-us.btn,
.contact-form .submit-btn-contact-us.btn:hover,
.contact-form .submit-btn-contact-us.btn:focus,
.contact-form .submit-btn-contact-us.btn:active {
  background: #013289;
  min-width: 300px;
}
.contact-form .form-label {
  font-weight: 700;
  color: #012970;
}